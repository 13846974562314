import ng from 'angular';
import {
  AcpOrderCardModel,
  AcpProgressBarModel,
  CardOwner,
  OrderCardStep
} from 'components/card-domain';
import { AcpBrandingModel } from 'core';
import acpSelectCardOwnerTemplate from './templates/acp-select-card-owner.html';

export class AcpSelectCardOwnerComponentCtrl
  implements nsUtils.NsComponentController {
  private static getPrimaryCardHolder(owners: CardOwner[]): CardOwner {
    return owners.filter((owner) => {
      return owner.primary;
    })[0];
  }
  viewModel: any;
  cardOwners: CardOwner[];
  steps: OrderCardStep[];
  progressIndicator: number;
  addAnotherCardOption = false;
  branding: any;
  cardConstant: any;
  userPermissions?: nsUtils.NsPermissionResult;
  isLoading = true;
  serverErrors: string[] = [];
  fieldErrors: { [fieldName: string]: string[] } = {};
  nsCardOwnerForm: { messages: object };

  constructor(
    nsComponentDecorator,
    private acpMedia: any,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $mdDialog: ng.material.IDialogService,
    private acpOrderCardModel: AcpOrderCardModel,
    private acpProgressBarModel: AcpProgressBarModel,
    private nsInPageFlow: any,
    private acpBrandingModel: AcpBrandingModel,
    private ACP_CARD_CONSTANTS: any,
    private nsPermissions: nsUtils.NsPermissionsService,
    private nsUtil: nsUtils.NsUtilService // private acpCoreDispatcher: any
  ) {
    'ngInject';
    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  $onInit(): void {
    // Perform initialization here
    this.signalListeners();
    this.$tiePermissions('permissions', [
      'orderAdditionalCardEligible',
      'isSecondaryCardHolderAllowed',
      'isNewCardHolderAllowed'
    ]);
    this.cardConstant = this.ACP_CARD_CONSTANTS;
    this.branding = this.acpBrandingModel.getBranding();
    this.initialization();
    this.getCardOwners();
  }

  $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
  $tiePermissions?(property: string, permissions: string[]);

  async confirmContinue(): Promise<void> {
    try {
      this.isLoading = true;
      this.resetFormErrors();
      await this.createOwner(this.viewModel);
      this.acpProgressBarModel.next();
      await this.acpOrderCardModel.setCardOwnerModel(this.viewModel);
      if (Object.keys(this.viewModel).length > 1) {
        // if new card owner is created
        this.cardOwners = await this.acpOrderCardModel.getCardOwners();
      }
      this.viewModel = this.nsUtil.assign({}, { id: this.viewModel.id });
      this.nsInPageFlow.push({
        template: this.steps[this.acpProgressBarModel.getCurrentStep()]
          .component
      });
    } catch (error) {
      this.onSubmitError(error);
    } finally {
      this.isLoading = false;
    }
  }

  continuePopup(firstName: string, lastName: string): void {
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet
        .show({
          clickOutsideToClose: false,
          template: `<md-bottom-sheet class="change-name-bottomsheet"><acp-change-name-dialog first-name="${firstName}" last-name="${lastName}"></acp-change-name-dialog></md-bottom-sheet>`
        })
        .then(ng.noop, this.confirmContinue.bind(this));
    } else {
      this.$mdDialog
        .show({
          parent: ng.element(document.body),
          template: `<md-dialog><acp-change-name-dialog first-name="${firstName}" last-name="${lastName}"></acp-change-name-dialog></md-dialog>`
        })
        .then(ng.noop, this.confirmContinue.bind(this));
    }
  }

  continue(firstName: string, lastName: string): void {
    if (this.viewModel.id === this.cardConstant.NEW_CARD_OWNER) {
      this.continuePopup(firstName, lastName);
    } else {
      this.confirmContinue();
    }
  }

  cancel(): void {
    this.acpOrderCardModel.reset();
    this.nsInPageFlow.close();
  }

  isSecondaryCardHolderAllowed(cardOwner: CardOwner): boolean {
    return (
      (cardOwner && cardOwner.primary) ||
      (this.userPermissions &&
        this.userPermissions.orderAdditionalCardEligible &&
        this.userPermissions.isSecondaryCardHolderAllowed)
    );
  }

  isNewCardHolderAllowed(): boolean {
    return (
      this.cardOwners &&
      this.cardOwners.length < this.ACP_CARD_CONSTANTS.MAX_CARDOWNERS_ALLOWED &&
      this.userPermissions &&
      this.userPermissions.orderAdditionalCardEligible &&
      this.userPermissions.isSecondaryCardHolderAllowed &&
      this.userPermissions.isNewCardHolderAllowed
    );
  }

  private signalListeners(): void {
    this.$onValue(this.nsPermissions.permissions, (permissions) => {
      this.userPermissions = permissions;
    });
  }

  private onSubmitError(response): void {
    if (response) {
      this.serverErrors = response._server_errors;
      this.fieldErrors = this.nsUtil.getFieldErrors(response);
    }
  }

  private async createOwner(owner: CardOwner): Promise<void> {
    try {
      if (owner.id === this.ACP_CARD_CONSTANTS.NEW_CARD_OWNER) {
        const newOwner: CardOwner = await this.acpOrderCardModel.createOwner(
          owner
        );
        owner.id = newOwner.id;
      }
    } catch (ex) {
      throw ex;
    }
  }

  private resetFormErrors(): void {
    this.serverErrors = [];
    this.fieldErrors = {};
    this.nsCardOwnerForm.messages = {};
  }

  private async initialization(): Promise<void> {
    this.viewModel = {};
    this.steps = await this.acpProgressBarModel.getSteps();
    this.progressIndicator = this.acpProgressBarModel.getCurrentStep();
  }

  private getCardOwners(): void {
    this.acpOrderCardModel.getCardOwners().then((owners) => {
      this.cardOwners = owners;
      this.viewModel.id = AcpSelectCardOwnerComponentCtrl.getPrimaryCardHolder(
        this.cardOwners
      ).id;
      this.isLoading = false;
    });
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpSelectCardOwnerComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpSelectCardOwnerComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpSelectCardOwnerTemplate
};
