import {
  AcpOrderCardModel,
  Address,
  CardOwner,
  CardOwnerAddressOptions
} from 'components/card-domain';
import { AcpCardShippingService } from './acp-card-shipping-service';
import { AcpAnalytics } from 'core';
import acpCardShippingAddressTemplate from './templates/acp-card-shipping-address.html';

export class AcpCardShippingAddressComponentCtrl
  implements nsUtils.NsComponentController {
  addressOptions: CardOwnerAddressOptions[];
  addressType: string;
  firstName: string;
  lastName: string;
  fullAddress: string;
  blankAddress: Address;
  shippingAddress: Address;
  cardOwner: CardOwner;
  form: any;
  nsShippingForm: { messages: object };
  serverErrors: string[];
  fieldErrors: string[];
  private cardOwnerDetails: CardOwner;

  constructor(
    private acpOrderCardModel: AcpOrderCardModel,
    private nsUtil: nsUtils.NsUtilService,
    private ACP_CARD_CONSTANTS,
    private acpCardShippingService: AcpCardShippingService,
    private acpAnalytics: AcpAnalytics
  ) {
    'ngInject';
    // If methods such as $onValue and $tie are needed:
    // nsComponentDecorator(this, this);
  }

  $onChanges(changeObject) {
    if (changeObject.cardOwner.currentValue !== undefined) {
      this.blankAddress = new Address();
      const cardOwner = this.cardOwner;
      if (cardOwner.id === this.ACP_CARD_CONSTANTS.NEW_CARD_OWNER) {
        this.getCardOwnerAddress(cardOwner);
      } else {
        const cardOwnerDetails = this.acpOrderCardModel.getCardOwnerModel();
        this.getCardOwnerAddress(cardOwnerDetails);
      }
      this.verifyPOBox();
    }
  }

  selectAddress() {
    this.form = this.nsShippingForm;
    this.acpCardShippingService.modifyIsPOBox(false);
    if (
      this.addressType ===
      this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS.NEW_ADDRESS
    ) {
      this.nsUtil.assign(
        this.cardOwnerDetails[this.addressType],
        this.blankAddress
      );
      this.shippingAddress = this.cardOwnerDetails[this.addressType];
      const data = {
        category: 'ACP_Cards',
        action: 'Send_To_Different_Address',
        label: 'Order_Another_Card'
      };
      this.acpAnalytics.sendEvent(data.category, data.action, data.label);
    } else {
      this.shippingAddress = null;
      this.resetFormError();
    }

    this.fullAddress = this.cardOwnerDetails[this.addressType].line1;
    if (this.cardOwnerDetails[this.addressType].line2) {
      this.fullAddress = `${this.fullAddress} ${
        this.cardOwnerDetails[this.addressType].line2
      }`;
    }
    this.verifyPOBox();
  }

  isNewAddress() {
    return (
      this.addressType ===
      this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS.NEW_ADDRESS
    );
  }

  async verifyPOBox(): Promise<void> {
    if (
      this.addressType ===
      this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS.NEW_ADDRESS
    ) {
      if (this.acpCardShippingService.isPOBox) {
        if (
          this.acpCardShippingService.checkFEAddressIsPoBox(
            this.cardOwnerDetails[this.addressType].line1
          )
        ) {
          this.acpCardShippingService.modifyIsPOBox(true);
        }
      }
      if (
        this.acpCardShippingService.checkFEAddressIsPoBox(
          this.cardOwnerDetails[this.addressType].line1
        )
      ) {
        this.acpCardShippingService.modifyIsPOBox(true);
      } else {
        this.acpCardShippingService.modifyIsPOBox(false);
      }
    } else {
      const isAddressPOBox = await this.serviceChechPOBox(this.fullAddress);
      this.acpCardShippingService.modifyIsPOBox(isAddressPOBox);
    }
  }

  async serviceChechPOBox(addressToCheck): Promise<boolean> {
    if (addressToCheck) {
      const validationPOBox = await this.acpCardShippingService.checkAddressIsPoBox(
        {
          address: addressToCheck
        }
      );
      return validationPOBox;
    } else {
      return false;
    }
  }

  private resetFormError(): void {
    this.serverErrors = [];
    this.fieldErrors = [];
    if (this.nsShippingForm) {
      this.nsShippingForm.messages = {};
    }
  }

  private getCardOwnerAddress(cardowner: CardOwner): void {
    this.cardOwnerDetails = cardowner;
    this.firstName = this.cardOwnerDetails.first_name;
    this.lastName = this.cardOwnerDetails.last_name;
    this.addressOptions = this.acpOrderCardModel.setAddressOptions(
      this.cardOwnerDetails
    );
    this.addressType = this.addressOptions[0].value;
    this.selectAddress();
  }
}

export const acpCardShippingAddressComponent: ng.IComponentOptions = {
  bindings: {
    shippingAddress: '=',
    cardOwner: '<',
    isCardFlowLostStolen: '=?',
    serverErrors: '=?',
    fieldErrors: '=?',
    form: '=?',
    addressType: '=?'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardShippingAddressComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardShippingAddressTemplate
};
