import ng from 'angular';
import { AcpCardShippingService } from './acp-card-shipping-service';
import {
  AcpOrderCardModel,
  CardBrand,
  CardShippingMethod
} from 'components/card-domain';
import acpCardShippingMethodsTemplate from './templates/acp-card-shipping-methods.html';

export class AcpCardShippingMethodsComponentCtrl
  implements nsUtils.NsComponentController {
  shippingMethodFee: number;
  shippingMethods: CardShippingMethod[];
  shippingMethod: CardShippingMethod;
  selectedCard: CardBrand;
  methodIdx = -1;

  constructor(
    private acpMedia: any,
    private acpOrderCardModel: AcpOrderCardModel,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $mdDialog: ng.material.IDialogService,
    private acpCardShippingService: AcpCardShippingService,
    private nsUtil: nsUtils.NsUtilService,
    public ACP_CARD_CONSTANTS
  ) {
    'ngInject';
    // nsComponentDecorator(this, this);
  }

  $onChanges(changesObject): void {
    if (changesObject.selectedCard.currentValue !== undefined) {
      this.acpCardShippingService.isPOBox;
      this.acpOrderCardModel
        .getCardShippingMethods(this.selectedCard)
        .then((shippingMethods) => {
          this.shippingMethods = shippingMethods;
          if (this.shippingMethods.length) {
            this.methodIdx = 0;
            this.nsUtil.assign(
              this.shippingMethod,
              this.shippingMethods[this.methodIdx]
            );
          }
        });
    }
  }

  selectMethod(): void {
    if (this.methodIdx >= 0) {
      this.nsUtil.assign(
        this.shippingMethod,
        this.shippingMethods[this.methodIdx]
      );
    }
    this.shippingMethodFee = this.shippingMethod.feeAmount;
  }

  methodError(): void {
    this.methodIdx = 0;
    this.nsUtil.assign(
      this.shippingMethod,
      this.shippingMethods[this.methodIdx]
    );
    this.shippingMethodFee = this.shippingMethod.feeAmount;
  }

  openExpeditePopup(feeAmount: number): void {
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet
        .show({
          clickOutsideToClose: false,
          template: `<md-bottom-sheet class="expedite-dialog-bottomsheet"><acp-expedite-dialog shipping-method-fee="${feeAmount}"></acp-expedite-dialog></md-bottom-sheet>`
        })
        .then(ng.noop, this.methodError.bind(this));
    } else {
      this.$mdDialog
        .show({
          parent: ng.element(document.body),
          template: `<md-dialog><acp-expedite-dialog check-radio shipping-method-fee="${feeAmount}"></acp-expedite-dialog></md-dialog>`
        })
        .then(ng.noop, this.methodError.bind(this));
    }
  }
}

export const acpCardShippingMethodsComponent: ng.IComponentOptions = {
  bindings: {
    shippingMethod: '=',
    shippingMethodFee: '=?',
    selectedCard: '<'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardShippingMethodsComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardShippingMethodsTemplate
};
