import { AcpApptentivePlugin } from 'components/apptentive-domain';
import {
  AcpCardModel,
  AcpOrderCardModel,
  AcpProgressBarModel,
  Address,
  CardBrand,
  CardImage,
  CardOwner,
  CardShippingMethod,
  OrderCardStep
} from 'components/card-domain';
import acpOrderCardSummaryTemplate from './templates/acp-order-card-summary.html';

export class AcpOrderCardSummaryComponentCtrl
  implements nsUtils.NsComponentController {
  public viewModel: any = {};
  public progressIndicator: number;
  public cardOwner: CardOwner;
  public cardBrand: CardBrand;
  public cardShippingAddress: Address;
  public isLoading: boolean = false;
  public serverErrors: string[] = [];
  public steps: OrderCardStep[];
  public isTroobaEnabled: boolean = false;
  public errors: string[] = [
    'account.insufficient_funds',
    'account.max_cards',
    'card.order_failed',
    'card_brand.custom_brand_not_supported',
    'card_brand.not_available',
    'resource.invalid'
  ];

  constructor(
    private acpProgressBarModel: AcpProgressBarModel,
    private nsInPageFlow: any,
    private acpOrderCardModel: AcpOrderCardModel,
    private ACP_CARD_CONSTANTS: any,
    private acpCardModel: AcpCardModel,
    private nsUtil: nsUtils.NsUtilService,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpCoreDispatcher: any,
    private nsStorage: any,
    private ACP_STORAGE_KEYS: any,
    private $window: ng.IWindowService,
    private acpApptentivePlugin: AcpApptentivePlugin
  ) {
    'ngInject';
    // If methods such as $onValue and $tie are needed:
    // nsComponentDecorator(this, this);
  }

  public $onInit() {
    // Perform initialization here
    this.initialization();
  }

  public cancel() {
    this.nsInPageFlow.close();
  }
  // TODO: Error handling
  public async submitOrder(): Promise<void> {
    try {
      this.isLoading = true;
      await this.acpOrderCardModel.placeOrder(
        this.cardOwner,
        this.cardBrand,
        this.viewModel.shippingMethod
      );
      this.acpCardModel.resetCards();
      try {
        this.acpApptentivePlugin.sendEvent('Order_card_success');
      } catch (error) {
        this.acpApptentivePlugin.errorLog(
          'AcpOrderCardSummaryComponentCtrl',
          error
        );
      }
      this.nsInPageFlow.close();
      if (this.isTroobaEnabled) {
        this.nsStorage.session(this.ACP_STORAGE_KEYS.ACP_CARD_ORDER_TOAST, {
          toastMessage: 'cardOrder'
        });
        this.$window.location.assign('dashboard');
      } else {
        this.acpCoreDispatcher.toast.show.emit({
          toastClass: 'success',
          content: 'msg-card-ordered:components/order-card-summary',
          hideDelay: 4000
        });
      }
    } catch (error) {
      this.onSubmitError(error);
    } finally {
      this.isLoading = false;
    }
  }

  public isStandardShipping(): boolean {
    const shippingMethod: CardShippingMethod = this.viewModel.shippingMethod;
    if (
      shippingMethod &&
      shippingMethod.feeAmount === this.ACP_CARD_CONSTANTS.FEE_AMOUNT_ZERO
    ) {
      return true;
    }
    return false;
  }

  public getShippingLabel(): string {
    if (this.viewModel.shippingMethod) {
      return this.viewModel.shippingMethod.label;
    }
    return '';
  }

  public isFreeShipping(): boolean {
    return (
      this.viewModel.shippingMethod &&
      this.viewModel.shippingMethod.feeAmount ===
        this.ACP_CARD_CONSTANTS.FEE_AMOUNT_ZERO
    );
  }

  public getTotal(): number {
    if (this.cardBrand && this.viewModel.shippingMethod) {
      return this.nsUtil.toDollars(
        this.cardBrand.price + this.viewModel.shippingMethod.feeAmount
      );
    }
    return this.nsUtil.toDollars(0);
  }

  public getShippingFee(): number {
    if (this.viewModel.shippingMethod) {
      return this.nsUtil.toDollars(this.viewModel.shippingMethod.feeAmount);
    }
    return this.nsUtil.toDollars(0);
  }

  public getImage(image: CardImage): string {
    return this.acpCardModel.getImage(image);
  }

  public getImageWidth(image: CardImage): string {
    if (image && image.width) {
      return this.acpCardModel.getImageWidth(image.width.split(','));
    }
    return '0';
  }

  public getCardFee(): number {
    if (this.cardBrand) {
      return this.nsUtil.toDollars(this.cardBrand.price);
    }
    return this.nsUtil.toDollars(0);
  }

  private onSubmitError(response): void {
    this.serverErrors = ['server.unknown'];
    if (
      response &&
      response._server_errors &&
      response._server_errors.length > 0 &&
      this.errors.indexOf(response._server_errors[0]) !== -1
    ) {
      this.serverErrors = response._server_errors;
    }
  }

  private async initialization(): Promise<void> {
    this.isTroobaEnabled = await this.nsPermissions.requestPermission(
      'isTroobaEnabled'
    );
    this.steps = await this.acpProgressBarModel.getSteps();
    this.progressIndicator = this.acpProgressBarModel.getCurrentStep();
    this.getOrderSummary();
  }

  private getOrderSummary(): void {
    this.cardOwner = this.acpOrderCardModel.getCardOwnerModel();
    this.cardBrand = this.acpOrderCardModel.getSelectedCard();
    this.cardShippingAddress = this.getUserShipToAddress(this.cardOwner);

    this.viewModel.name = `${this.cardOwner.first_name} ${this.cardOwner.last_name}`;
    this.viewModel.address = this.getFomattedAddress(this.cardShippingAddress);
    this.viewModel.zip = this.cardShippingAddress.zip;
    this.viewModel.shippingMethod = this.getShippingDetails();
  }

  private getUserShipToAddress(cardOwner: CardOwner): Address {
    const shipAddress = this.acpOrderCardModel.getShippingAddress();
    if (shipAddress && shipAddress.shippingAddress) {
      return shipAddress.shippingAddress;
    } else if (cardOwner.ship_to_address) {
      return cardOwner.ship_to_address;
    } else {
      return cardOwner.residential_address;
    }
  }

  private getFomattedAddress(address: Address): string {
    let fullAdress = address.line1;
    if (address.line2) {
      fullAdress = `${fullAdress} ${address.line2}`;
    }
    return fullAdress;
  }

  private getShippingDetails(): CardShippingMethod {
    const shipToAddress = this.acpOrderCardModel.getShippingAddress();
    return shipToAddress.shippingMethod;
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpAcpOrderCardSummaryComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpOrderCardSummaryComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpOrderCardSummaryTemplate
};
