import {
  AcpOrderCardModel,
  AcpProgressBarModel,
  CardBrand,
  OrderCardStep
} from 'components/card-domain';
import acpDesignCardTemplate from './templates/acp-design-card.html';

export class AcpDesignCardComponentCtrl
  implements nsUtils.NsComponentController {
  public galleryUrl: string;
  public progressIndicator: number;
  public steps: OrderCardStep[];
  public cardBrand: CardBrand;
  public onIframeLoad: (element: { iframe: HTMLIFrameElement }) => void;
  constructor(
    private acpProgressBarModel: AcpProgressBarModel,
    private $sce: ng.ISCEService,
    private acpOrderCardModel: AcpOrderCardModel,
    private nsInPageFlow: any
  ) {
    'ngInject';
    // If methods such as $onValue and $tie are needed:
    // nsComponentDecorator(this, this);
    this.onIframeLoad = (element: { iframe: HTMLIFrameElement }): void => {
      try {
        if (
          element.iframe.contentWindow.location.origin ===
          this.acpOrderCardModel.getOrigin()
        ) {
          const queryParams: { CardImageId?: string } = this.getQueryParams(
            element.iframe.contentWindow.location.href
          );
          this.continue(queryParams.CardImageId);
        }
      } catch (e) {
        // We might have an error throw for Cross-Domain issues,
        // but once we are redirected back to our main page,
        // we will successfully be able to detect the url change.
        // This error is safe to ignore
      }
    };
  }

  public $onInit() {
    // Perform initialization here
    this.initialization();
  }

  private getQueryParams(url: string): { CardImageId?: string } {
    const vars = {};
    let hash = [];
    const hashes = url.slice(url.indexOf('?') + 1).split('&');
    for (const key of hashes) {
      hash = key.split('=');
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  private continue(imageRefId: string): void {
    this.cardBrand.image_ref_id = imageRefId;
    this.acpProgressBarModel.next();
    this.acpOrderCardModel.setSelectedCard(this.cardBrand);
    this.nsInPageFlow.push({
      template: this.steps[this.acpProgressBarModel.getCurrentStep()].component
    });
  }

  private async initialization(): Promise<void> {
    this.steps = await this.acpProgressBarModel.getSteps();
    this.progressIndicator = this.acpProgressBarModel.getCurrentStep();
    this.cardBrand = this.acpOrderCardModel.getSelectedCard();
    this.galleryUrl = this.$sce.trustAsResourceUrl(this.cardBrand.custom_url);
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpCardDesignComponentComponent: ng.IComponentOptions = {
  controller: AcpDesignCardComponentCtrl,
  controllerAs: 'vm',
  template: acpDesignCardTemplate
};
