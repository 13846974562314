import {
  AcpOrderCardModel,
  AcpProgressBarModel,
  Address,
  CardBrand,
  CardOwner,
  CardShipping,
  CardShippingMethod,
  OrderCardStep
} from 'components/card-domain';
import acpSelectShippingAddressTemplate from './templates/acp-select-shipping-address.html';

export class AcpSelectShippingAddressComponentCtrl
  implements nsUtils.NsComponentController {
  public steps: OrderCardStep[];
  public progressIndicator: number;
  public shippingData: CardShipping;
  public shippingMethod: CardShippingMethod;
  public selectedCard: CardBrand;
  public shippingAddress: Address;
  public cardOwner: CardOwner;
  public isLoading: boolean = false;
  public serverErrors: string[] = [];
  public fieldErrors: { [fieldName: string]: string[] } = {};
  public form: { messages: object };

  constructor(
    private acpProgressBarModel: AcpProgressBarModel,
    private nsInPageFlow: any,
    private acpOrderCardModel: AcpOrderCardModel,
    private nsUtil: nsUtils.NsUtilService
  ) {
    'ngInject';
    // If methods such as $onValue and $tie are needed:
    // nsComponentDecorator(this, this);
    this.shippingMethod = new CardShippingMethod();
    this.shippingAddress = new Address();
  }

  public $onInit() {
    // Perform initialization
    this.initialization();
  }

  public async continue() {
    this.resetFormError();
    this.shippingData.shippingAddress = null;
    if (this.shippingAddress) {
      this.shippingData.shippingAddress = this.nsUtil.assign(
        new Address(),
        this.shippingAddress
      );
    }

    this.shippingData.shippingMethod = this.nsUtil.assign(
      new CardShippingMethod(),
      this.shippingMethod
    );

    try {
      this.isLoading = true;
      await this.updateShippingAddress(this.cardOwner, this.shippingData);
      this.acpOrderCardModel.setShippingAddress(this.shippingData);
      this.acpProgressBarModel.next();
      this.nsInPageFlow.push({
        template: this.steps[this.acpProgressBarModel.getCurrentStep()]
          .component
      });
    } catch (error) {
      this.onSubmitError(error);
    } finally {
      this.isLoading = false;
    }
  }

  public cancel() {
    this.nsInPageFlow.close();
  }

  public disabled() {
    if (this.shippingAddress) {
      return !(this.shippingAddress.line1 && this.shippingAddress.zip);
    }
    return false;
  }

  private onSubmitError(response): void {
    if (response) {
      this.serverErrors = response._server_errors;
      this.fieldErrors = this.nsUtil.getFieldErrors(response);
    }
  }

  private resetFormError(): void {
    this.serverErrors = [];
    this.fieldErrors = {};
    if (this.form) {
      this.form.messages = {};
    }
  }

  private async updateShippingAddress(
    owner: CardOwner,
    shipToAddress: CardShipping
  ): Promise<void> {
    try {
      if (shipToAddress && shipToAddress.shippingAddress) {
        await this.acpOrderCardModel.updateShippingAddress(
          owner,
          shipToAddress.shippingAddress
        );
      }
    } catch (ex) {
      throw ex;
    }
  }

  private async initialization(): Promise<void> {
    this.steps = await this.acpProgressBarModel.getSteps();
    this.progressIndicator = this.acpProgressBarModel.getCurrentStep();

    this.shippingData = {
      shippingAddress: new Address(),
      shippingMethod: new CardShippingMethod()
    };

    this.cardOwner = this.acpOrderCardModel.getCardOwnerModel();
    this.selectedCard = this.acpOrderCardModel.getSelectedCard();
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpSelectShippingAddressComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpSelectShippingAddressComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpSelectShippingAddressTemplate
};
