import ng from 'angular';
import { acpComponentApptentiveDomain } from 'components/apptentive-domain';

import acpCardDomainModule from 'components/card-domain';
import acpCore from 'core';

import { acpCardDesignComponentComponent } from './acp-card-design-component';
import { acpCardOrderProcessingComponent } from './acp-card-order-processing-component';
import { acpCardShippingAddressComponent } from './acp-card-shipping-address-component';
import { acpCardShippingMethodsComponent } from './acp-card-shipping-methods-component';
import { AcpCardShippingService } from './acp-card-shipping-service';
import { acpChangeNameDialogComponent } from './acp-change-name-dialog-component';
import { acpExpediteDialogComponent } from './acp-expedite-dialog-component';
import { acpOrderCardCommonQuestionComponent } from './acp-order-card-common-question-component';
import { acpOrderCardLandingComponent } from './acp-order-card-landing-component';
import { acpAcpOrderCardSummaryComponent } from './acp-order-card-summary-component';
import { acpSelectCardDesignComponent } from './acp-select-card-design-component';
import { acpSelectCardOwnerComponent } from './acp-select-card-owner-component';
import { acpSelectShippingAddressComponent } from './acp-select-shipping-address-component';
import ownerCreationFormErrorsContent from './card-owner-form-error-content.yml';
import acpCardShippingAddressContent from './card-shipping-address-content.yml';
import acpChangeNameDialogContent from './change-name-dialog.yml';
import acpExpediteDialogContent from './expedite-dialog-content.yml';
import acpOrderCardCommonQuestionContent from './order-card-common-question-content.yml';
import acpOrderCardLandingContent from './order-card-landing-content.yml';
import acpOrderCardSummaryContent from './order-card-summary-content.yml';
import acpSelectCardDesignContent from './select-card-design-content.yml';
import acpSelectCardOwnerContent from './select-card-owner-content.yml';
import acpSelectShippingAddressContent from './select-shipping-address-content.yml';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.order-card
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-select-card-owner></acp-select-card-owne>
 * ```
 *
 * ```js
 * import {acpOrderCardModule} from 'components/acpCardLanding/order-card';
 *
 * angular.module('acp.component.card-landing.example', [
 *     acpOrderCardModule.name
 * ]);
 * ```
 */

export const acpOrderCardModule = ng
  .module('acp.component.order-card', [
    acpCore.name,
    acpCardDomainModule.name,
    acpComponentApptentiveDomain.name,
    'netspend.legos.progressbar',
    'netspend.legos.carousel'
  ])
  .component('acpSelectCardOwner', acpSelectCardOwnerComponent)
  .component('acpChangeNameDialog', acpChangeNameDialogComponent)
  .component('acpSelectCardDesign', acpSelectCardDesignComponent)
  .component('acpSelectShippingAddress', acpSelectShippingAddressComponent)
  .component('acpOrderCardSummary', acpAcpOrderCardSummaryComponent)
  .component('acpCardShippingAddress', acpCardShippingAddressComponent)
  .component('acpCardShippingMethods', acpCardShippingMethodsComponent)
  .component('acpExpediteDialog', acpExpediteDialogComponent)
  .component('acpOrderCardLanding', acpOrderCardLandingComponent)
  .component('acpOrderCardCommonQuestion', acpOrderCardCommonQuestionComponent)
  .component('acpCardDesign', acpCardDesignComponentComponent)
  .component('acpCardOrderProcessing', acpCardOrderProcessingComponent)
  .service('acpCardShippingService', AcpCardShippingService)
  .run((contentSectionCache) => {
    'ngInject';

    contentSectionCache.put(
      'components/order-card-select-owner',
      acpSelectCardOwnerContent
    );
    contentSectionCache.put(
      'components/change-name-dialog',
      acpChangeNameDialogContent
    );
    contentSectionCache.put(
      'components/select-card-design',
      acpSelectCardDesignContent
    );
    contentSectionCache.put(
      'components/select-shipping-address',
      acpSelectShippingAddressContent
    );
    contentSectionCache.put(
      'components/card-shipping-address',
      acpCardShippingAddressContent
    );
    contentSectionCache.put(
      'components/expedite-dialog',
      acpExpediteDialogContent
    );
    contentSectionCache.put(
      'components/order-card-summary',
      acpOrderCardSummaryContent
    );
    contentSectionCache.put(
      'components/owner-creation-error',
      ownerCreationFormErrorsContent
    );
    contentSectionCache.put(
      'components/order-card-landing',
      acpOrderCardLandingContent
    );
    contentSectionCache.put(
      'components/order-card-common-question',
      acpOrderCardCommonQuestionContent
    );
  });
