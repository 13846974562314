import acpExpediteDialogTemplate from './templates/acp-expedite-dialog.html';

export class AcpExpediteDialogComponentCtrl
  implements nsUtils.NsComponentController {
  public shippingMethodFee: number;

  constructor(private $mdBottomSheet, private $mdDialog) {
    'ngInject';
    // nsComponentDecorator(this, this);
  }

  public closePopup() {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }

  public cancel() {
    this.$mdDialog.cancel();
    this.$mdBottomSheet.cancel();
  }
}

export const acpExpediteDialogComponent: ng.IComponentOptions = {
  bindings: {
    shippingMethodFee: '<'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpExpediteDialogComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpExpediteDialogTemplate
};
