import {
  AcpCardModel,
  AcpOrderCardModel,
  AcpProgressBarModel,
  CardBrand,
  CardImage,
  CardOwner,
  OrderCardStep
} from 'components/card-domain';
import {
  AcpBrand,
  AcpBrandingClient,
  AcpDeviceDetectionService,
  AcpProfileClient,
  ProfileResponse
} from 'core';

import acpSelectCardDesignTemplate from './templates/acp-select-card-design.html';

export class AcpSelectCardDesignComponentCtrl
  implements nsUtils.NsComponentController {
  progressIndicator: number;
  steps: OrderCardStep[];
  cardBrands: CardBrand[];
  selectedCardBrand: CardBrand;
  selectedCardType: string;
  loading = true;
  brand: AcpBrand = this.acpBrandingClient.get();

  constructor(
    nsComponentDecorator,
    public ACP_CARD_CONSTANTS: any,
    private acpOrderCardModel: AcpOrderCardModel,
    private acpProgressBarModel: AcpProgressBarModel,
    private nsInPageFlow: any,
    private $filter: ng.IFilterService,
    private acpProfileClient: AcpProfileClient,
    private acpConfigurationService: any,
    private acpCardModel: AcpCardModel,
    private acpDeviceDetectionService: AcpDeviceDetectionService,
    private acpBrandingClient: AcpBrandingClient
  ) {
    'ngInject';
    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  $onInit() {
    this.selectedCardType = this.acpOrderCardModel.getCardType();
    this.initialization();
  }

  continue() {
    this.acpProgressBarModel.next();
    this.acpOrderCardModel.setSelectedCard(this.selectedCardBrand);
    this.nsInPageFlow.push({
      template: this.steps[this.acpProgressBarModel.getCurrentStep()].component
    });
  }

  cancel() {
    this.acpOrderCardModel.reset();
    this.nsInPageFlow.close();
  }

  $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  selectCard(cardBrand: CardBrand): void {
    this.selectedCardBrand = cardBrand;
  }

  isSelected(cardBrand: CardBrand): boolean {
    return (
      this.selectedCardBrand &&
      this.selectedCardBrand.issuer_number === cardBrand.issuer_number
    );
  }

  getPrice(amount: number): number {
    return this.acpOrderCardModel.convertCentToDollar(amount);
  }

  getImage(image: CardImage): string {
    return this.acpCardModel.getImage(image);
  }

  getImageWidth(image: CardImage): string {
    if (image && image.width) {
      return this.acpCardModel.getImageWidth(image.width.split(','));
    }
    return '0';
  }

  isVerticalImage(image: CardImage): boolean {
    return this.acpCardModel.isImageVertical(image);
  }

  private async initialization(): Promise<void> {
    await this.setCardOwnerUser();
    this.steps = await this.acpProgressBarModel.getSteps();
    this.progressIndicator = this.acpProgressBarModel.getCurrentStep();
    this.acpOrderCardModel.getCardBrands().then((cards) => {
      if (this.brand.id === 'westernunionaa') {
        this.cardBrands = this.$filter('orderBy')(cards, 'issuer_number');
      } else {
        this.cardBrands = this.$filter('orderBy')(cards, 'description');
      }
      const selectedCard = this.acpOrderCardModel.getSelectedCard();
      this.selectCard(selectedCard ? selectedCard : this.cardBrands[0]);
      if (this.acpDeviceDetectionService.isMobile()) {
        this.nsInPageFlow.reposition();
      }
      this.loading = false;
    });
  }

  private async setCardOwnerUser(): Promise<void> {
    const programType = this.acpConfigurationService.getProgramType();
    const cardOwner: CardOwner = this.acpOrderCardModel.getCardOwnerModel();
    if (
      (programType === this.ACP_CARD_CONSTANTS.PROGRAM_TYPE.CONSUMER_DDA ||
        programType === this.ACP_CARD_CONSTANTS.PROGRAM_TYPE.BAW_DDA) &&
      !cardOwner
    ) {
      const profile: ProfileResponse = await this.acpProfileClient.get();
      this.acpOrderCardModel.setCardOwnerModel(profile.user.primary_card_owner);
    }
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpSelectCardDesignComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpSelectCardDesignComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpSelectCardDesignTemplate
};
