import {
  AcpOrderCardModel,
  AcpProgressBarModel,
  CardTypeFeesResponse,
  OrderCardStep
} from 'components/card-domain';
import { AcpAnalytics } from 'core';
import acpOrderCardLandingTemplate from './templates/acp-order-card-landing.html';

export class AcpOrderCardLandingComponentCtrl
  implements nsUtils.NsComponentController {
  selectedCardType: string;
  steps: OrderCardStep[];
  progressIndicator: number;
  response: CardTypeFeesResponse;
  brand: string;
  isCustomCard = false;

  constructor(
    nsComponentDecorator,
    private nsInPageFlow: any,
    private acpProgressBarModel: AcpProgressBarModel,
    private acpConfigurationService: any,
    private acpOrderCardModel: AcpOrderCardModel,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpLogActionClient: any,
    private acpAnalytics: AcpAnalytics
  ) /* nsComponentDecorator */ {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  $onInit() {
    // Perform initialization here
    this.loadPageActionLogs();
    this.brand = this.acpConfigurationService.getBrand();
    this.initialization();
    this.handleListeners();
    this.requestPermissions();
    this.getCardTypeFees();
  }

  $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
  $tiePermissions?(property: string, permissions: string[]);

  async getCardTypeFees(): Promise<void> {
    this.response = await this.acpOrderCardModel.getCardTypeFee();
  }

  async continue(selectedCardType): Promise<void> {
    this.acpOrderCardModel.reset();
    this.acpOrderCardModel.setCardType(selectedCardType);
    this.steps = await this.acpProgressBarModel.getSteps();
    this.acpOrderCardModel.setCardTypeFee(
      this.response.plan.fees.custom_card_order
    );
    this.acpProgressBarModel.next();
    this.nsInPageFlow.push({
      template: this.steps[this.acpProgressBarModel.getCurrentStep()].component
    });
    this.acpLogActionClient.logUserAction({
      action: 'CARD_TYPE_CONTINUE_CLK'
    });
  }

  cancel(): void {
    this.acpOrderCardModel.reset();
    this.nsInPageFlow.close();
    this.acpLogActionClient.logUserAction({
      action: 'CARD_TYPE_CANCEL_CLK'
    });
  }

  canOrderCustomCard(): boolean {
    return this.isCustomCard;
  }

  canOrderPremiumCard(): boolean {
    return this.isCustomCard && this.brand === 'aceflare';
  }

  loadPageActionLogs(): void {
    this.acpLogActionClient.logUserAction({
      action: 'CARD_TYPE_PAGE_LOADED'
    });
    this.acpAnalytics.sendEvent(
      'ACP_Cards',
      'Viewed_Choose_Card_Type_Page',
      'acp-landing'
    );
  }

  private handleListeners(): void {
    this.$onValue(this.nsPermissions.permissions, (permissions) =>
      this.updatePermissions(permissions)
    );
  }

  private updatePermissions(permissions?: nsUtils.NsPermissionResult): void {
    this.isCustomCard = permissions && permissions.hasCustomCard;
  }

  private requestPermissions(): void {
    this.$tiePermissions('permissions', ['hasCustomCard']);
  }

  private async initialization(): Promise<void> {
    this.steps = await this.acpProgressBarModel.getSteps();
    this.progressIndicator = this.acpProgressBarModel.getCurrentStep();
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
}

export const acpOrderCardLandingComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpOrderCardLandingComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpOrderCardLandingTemplate
};
