import ng from 'angular';
import acpChangeNameDialogTemplate from './templates/acp-change-name-dialog.html';

export class AcpChangeNameDialogComponentCtrl
  implements nsUtils.NsComponentController {
  public firstName: string;
  public lastName: string;

  constructor(
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $mdDialog: ng.material.IDialogService
  ) {
    'ngInject';
    // nsComponentDecorator(this, this);
  }

  public continue() {
    this.$mdDialog.cancel();
    this.$mdBottomSheet.cancel();
  }

  public makeChanges() {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }
}

export const acpChangeNameDialogComponent: ng.IComponentOptions = {
  bindings: {
    firstName: '@',
    lastName: '@'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpChangeNameDialogComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpChangeNameDialogTemplate
};
