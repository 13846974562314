import {
  AcpOrderCardModel,
  AcpProgressBarModel,
  OrderCardStep
} from 'components/card-domain';
import { AcpBrandingModel } from 'core';
import acpOrderCardCommonQuestionTemplate from './templates/acp-order-card-common-question.html';

export class AcpOrderCardCommonQuestionComponentCtrl
  implements nsUtils.NsComponentController {
  public steps: OrderCardStep[];
  public progressIndicator: number;
  public slides: string[];
  public activeSlide: string;
  public carouselTemplate: string;
  public sliderPromise: any;
  public getCardType: string;
  public branding: any;
  public cardTypeFee: string;
  constructor(
    private acpProgressBarModel: AcpProgressBarModel,
    private nsInPageFlow: any,
    private $interval: ng.IIntervalService,
    private acpOrderCardModel: AcpOrderCardModel,
    private ACP_CARD_CONSTANTS: any,
    private acpBrandingModel: AcpBrandingModel
  ) /* nsComponentDecorator */ {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    // nsComponentDecorator(this, this);
  }

  public $onInit() {
    // Perform initialization here
    this.initialization();
    this.branding = this.acpBrandingModel.getBranding();
    this.getCardType = this.acpOrderCardModel.getCardType();
    this.cardTypeFee = this.acpOrderCardModel.getCardFee();
    if (this.getCardType === this.ACP_CARD_CONSTANTS.CUSTOM) {
      this.sliderHere();
    }
  }

  public sliderHere(): void {
    this.slides = [
      'components/order-card/image01_background.svg',
      'components/order-card/image02_background.svg',
      'components/order-card/image03_background.svg'
    ];
    this.activeSlide =
      'components/order-card/image01_background.svg';
    this.carouselTemplate =
      "<div class='slide'><img acp-image-src='{{data}}'/></div>";
    this.sliderPromise = this.$interval(() => {
      this.activeSlide = this.slides[this.slides.indexOf(this.activeSlide) + 1];
    }, 5000);
  }

  public $onDestroy() {
    this.$interval.cancel(this.sliderPromise);
  }

  public continue(): void {
    this.acpProgressBarModel.next();
    this.nsInPageFlow.push({
      template: this.steps[this.acpProgressBarModel.getCurrentStep()].component
    });
  }

  public cancel(): void {
    this.acpOrderCardModel.reset();
    this.nsInPageFlow.close();
  }

  private async initialization(): Promise<void> {
    this.steps = await this.acpProgressBarModel.getSteps();
    this.progressIndicator = this.acpProgressBarModel.getCurrentStep();
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpOrderCardCommonQuestionComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpOrderCardCommonQuestionComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpOrderCardCommonQuestionTemplate
};
